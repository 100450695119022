


.app-Name {
    position: absolute;
    top: 5%;
    left: 18.5%;
    color: #FFFFFF;
  }


.app-Name-1 {
  position: absolute;
  top: 45%;
  left: 15%;
  color: #FFFFFF;
}


@media (max-width: 768px) {
  .app-Name{
    left: 10%;
  }
}

