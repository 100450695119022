.Font12 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  color: #6c6c6c;
}
.truck-img {
  width: 302.03px;
  height: 124.55px;
  border-radius: 10px;
}
