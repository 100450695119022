.purpleButton {
  box-sizing: border-box;
  height: 35px;
  width: 200px;
  background: rgb(0, 0, 1);
  border: 1px solid #96a9ca;
  border-radius: 5px;
  color: white;
  font-family: "Nunito-Regular";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.purpleButton-2 {
  box-sizing: border-box;
  width: auto;
  height: auto;
  background: rgb(0, 0, 1);
  border: 1px solid #96a9ca;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  margin-left: 30px;
  padding: 5px;
}
.purpleButton-1 {
  box-sizing: border-box;
  width: -webkit-fill-available;
  height: auto;
  background: rgb(0, 0, 1);;
  border: 1px solid #96a9ca;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 5px;
}
.det-head {
  display: flex;
  justify-content: left;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: left;
  margin-top: 10px;
}
.descrip {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  margin-left: -15px;
  color: #787878;
}
.btm-box {
  width: 145px;
  height: 100px;
  border-radius: 20px;
  background-color: #dbdcdc;
  margin: 20px 0 20px 0;
}
.accountingSvg {
  background-color: rgba(213, 229, 229, 0.25);
  justify-content: center;
  margin-top: 12px;
  text-align: center;
}
.btm0 {
  margin-bottom: 0px !important;
}
/* .greenButton:hover {
        background: #ed752e;
      } */
