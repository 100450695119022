.options-truck-record{
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    gap: 7px;
}

.truck-input-field-box{
    background-color: #FFC106;
    border: 1px solid #000000;
    border-radius: 0 5px 5px 0;
}

.truck-input-field{
    position: relative;
    background: #FFC106;
    border: none;
    padding-left: 10px;
    color: #000000;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    height: 100%;
    font-size: 24px;
}

.truck-input-field:focus{
    outline: none;
}

.truck-input-field::placeholder{
    color: #6C6C6C3D;
}