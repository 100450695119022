.main-expense-cal{
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(108, 108, 108, 0.2);
    margin: 15px 20px 15px 20px;
    border-radius: 10px;
}

.expense-val{
    padding: 10px 10px 10px 10px;
    border-top: 1px solid rgba(108, 108, 108, 0.5);
}

.slider-bar{
    height: 20px;
    background-color: rgba(0, 0, 0, 1);
    width: 6px;
    border-radius: 3px;
    margin: 5px 0 5px 0px;
}

.main-expense-other-cal{
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(108, 108, 108, 0.2);
    margin: 15px 20px 15px 20px;
    border-radius: 10px;
}

.inputBox-mini {

    padding-left: 10px;
    color: #485B7C;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 1);
    height: 30px;
    background: rgba(77, 80, 115, 0.1);
  }
  .inputBox-mini:focus {
    outline: none;
  }
  .inputBox-mini::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
  }

  .sub-link-btn{
    background: rgba(77, 80, 115, 0.1);
    width: 30%;
    padding: 15px 0 15px 0;
    border-radius: 12px;

  }