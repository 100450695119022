.purpleBtnCenter {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 22px;
  /* width: auto; */
  height: 40px;
  background: #4d5073;
  border: 1px solid #96a9ca;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  margin: 0 30px 0 30px;
  padding: 5px;
}
.orLine {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #6c6c6c;
  margin: 10px 0 10px 0;
}
