.header-part {
    background-color: #d77a3e;
    color: white; 
    height: 150px;
    border-bottom-left-radius: 100px; 
    border-bottom-left-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .error1{
    font-size: 1rem;
    color: red;
  }

