.request-btn{
    background: rgba(234, 234, 234, 1);
    border-radius: 10px;
    padding: 5px;
    width: 70%;
}

.text-align-center-text{
    display: flex;
    align-items: center;
}

.request-btn-1{
    background: #000000;
    border-radius: 10px;
    padding: 5px;
    width: 70%;
    color: white;
}

.request-btn-2{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 5px;
    width: 70%;
    color: black;
    border: 1px solid rgba(0, 0, 0, 1)
}

.box-driver-search{
    box-shadow: 0px 4px 4px 0px #00000040;

}