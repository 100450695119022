.main-body {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito"), url("./assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito-Medium";
  src: local("Nunito"),
    url("./assets/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

.FontNB24 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* letter-spacing: 1px; */
}

.FontNB40 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  /* line-height: 32px; */
  /* letter-spacing: 1px; */
}

.FontNS20 {
  font-family: "Nunito-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* letter-spacing: 1px; */
}

.inputBoxAddCust {
  padding-left: 10px;
  color: #485b7c;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  height: 30px;
}
.inputBoxAddCust:focus {
  outline: none;
}
.inputBoxAddCust::placeholder {
  color: #e2e2e2;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
}

.greenButton {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  height: 35px;
  /* left: 282px; */
  /* top: 25px; */
  background: rgba(0, 0, 0, 1);
  border: 1px solid #96a9ca;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButton:hover {
  /* background: #4D5073; */
  background: #ed752e;
}

.greenButtonGlobal {
  box-sizing: border-box;
  /* position: absolute; */
  /* width: 113px; */
  /* height: 35px; */
  /* left: 282px; */
  /* top: 25px; */
  background: rgba(0, 0, 0, 1);
  border: 1px solid #96a9ca;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-family: "Nunito-Regular";
  font-style: normal;
}
.greenButtonGlobal:hover {
  /* background: #4D5073; */
  background: #ed752e;
}

.fontNB45 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
}

.fontNB20 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.fontNM18 {
  font-family: "Nunito-Medium";
  color: #25252d;
  font-weight: 400;
  font-size: 18px;
  line-height: 24.55px;
}

.fontNM12 {
  font-family: "Nunito-Regular";
  color: #25252d;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.fontNS12 {
  font-family: "Nunito-SemiBold";
  color: #25252d;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.fontNS16 {
  font-family: "Nunito-SemiBold";
  color: #25252d;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 24.55px; */
}

.fontNB24 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}

.fontNB18 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}


.fontNR10 {
  font-family: "Nunito-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}


.fontNB35 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
}

.fontNM16 {
  font-family: "Nunito-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}


.fontNB16 {
  font-family: "Nunito-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.fontNS15 {
  font-family: "Nunito-SemiBold";
  color: #25252d;
  font-weight: 600;
  font-size: 15px;
  /* line-height: 24.55px; */
}

.fontNS14 {
  font-family: "Nunito-SemiBold";
  color: #25252d;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 24.55px; */
}

.fontNS10 {
  font-family: "Nunito-SemiBold";
  color: #25252d;
  font-weight: 600;
  font-size: 10px;
  /* line-height: 24.55px; */
}

.fontNB16 {
  font-family: "Nunito-Bold";
  color: #25252d;
  font-weight: 700;
  font-size: 16px;
  line-height: 21.82px;
}









.inputBoxCust1{
  /* font-family: 'Roboto', sans-serif;
  color: #E2E2E2;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s; */
  padding-left: 10px;
  color: #25252D;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
    border: 1px solid #E2E2E2;
    height: 30px;
    width: 100%;
}
.inputBoxCust1:focus {outline:none;}
.inputBoxCust1::placeholder {
  color: #E2E2E2;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
}

.date-logo {

  color: black;
  /* position: relative; */
  /* top: -2rem; */
  /* left: -1.4rem; */
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

}

.add-attach-for-fastag-linked-vehicle-new {
  position: relative;
  /* left: 83px; */
  /* top: 28px; */
  background-color: #ffffff;
  border: none;
  width: 50px;
  padding: 0;
}

.add-attach-for-fastag-linked-vehicle-new:hover {
  background-color: #ffffff;
  border: none;
}

.add-attach-for-fastag-linked-vehicle-new:focus {
  background-color: #ffffff;
  border: none;
}

.add-lr-attachment {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  border: 2px #2e7c7b;
  border-style: dotted;
  opacity: 0;
  cursor: pointer;
}



.closeBtnWithBackground {
  position: absolute;
  right: -12px;
  top: -12px;
  border: 1px solid;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}

/* .ukpk{
  background : url("./assets/images/image11.png");
  object-fit: contain;
  width: 100%;
} */