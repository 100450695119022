.ExtraInfo{
  width: 30%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Extra-Info-Text{
  color: #6C6C6C;
}

.grey-box-mini{
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 0.5px solid;
  border-image-source: linear-gradient(91.34deg, rgba(0, 0, 0, 0.2) 0%, rgba(212, 213, 214, 0.2) 58.67%);
  border-radius: 10px;
  padding: 15px;
}

.grey-box-driver-sub-mini{
  border: 0.5px solid #000000;
  border-radius: 10px;
  background: #0000001A;
}

.grey-box-driver-sub-mini-interior{
  width: 45%;
  gap: 5px;
  padding:10px
}

.grey-box-mini-2{
  border: 1px solid #7C7C81;
  border-radius: 10px;
  padding: 15px;
}

.slick-dots {
  margin: 0 0 30px;
  padding: 0;
  display: block;
  text-align: center;
  line-height: 1;
}

.slick-dots li:hover,
.slick-dots li.slick-active {
  background-color: #fbd84e;
  width: 28px;
}

.after-before .slick-dots {
  bottom: -50px;
}

.slick-slide-dots-1 .slick-dots {
  position: absolute;
  bottom: 50px;
  width: 100%;
  margin: 0;
}
.slick-dots {
  margin: 0 0 30px;
  padding: 0;
  display: block;
  text-align: center;
  line-height: 1;
}

.slick-dots li:hover, .slick-dots li.slick-active {
  background-color: #fbd84e;
  width: 28px;
}

.slick-dots li {
  display: inline-block;
  list-style: none;
  display: inline-block;
  font-size: 0;
  height: 8px;
  width: 18px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: #f1f1f1;
  cursor: pointer;
  margin-top: 0;
  transition: all 0.3s ease 0s;
}

.slick-dots li button {
  display: none;
}