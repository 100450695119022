.model-width {
    max-width: 965px;
    /* border-radius: 10px; */
  }

  .overlay {
    position: fixed;
    top: 0;
    z-index:1;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-Images {
    /* height: 520px; */
    /* width: 700px; */
    background-color: #FFFFFF;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgb(8 7 16 / 60%);
    padding: 25px
}

.widthpopUP{
    width: 60vw;
  }

  .closeBtnWithBackground {
    position: absolute;
    right: -12px;
    top: -12px;
    border: 1px solid;
    border-radius: 20px;
    background: #FFFFFF;
    cursor: pointer;
  }