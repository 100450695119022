.inputBoxAddCust {
    padding-left: 10px;
    color: #485b7c;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 30px;
  }
  .inputBoxAddCust:focus {
    outline: none;
  }
  .inputBoxAddCust::placeholder {
    color: #e2e2e2;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
  }

  .temp1{
    width: 100%;
  }

.react-datepicker__input-container{
    width: 100%;
  }

.react-datepicker-wrapper{
    width: 100%;
  }