.paidbtn {

  /* background: #3eb655; */
  font-size: 12px;
  line-height: 16.37px;
  color: #f5f9f8;
  transition: transform 1s;
}
.paidbtnFont {
  text-align: center;
  padding: 2px;
}
.paidbtn:hover {
  font-weight: 900;
  color: #f5f9f8;
  transform: scale(1.2);
}
.payNowBtn {
  width: 80px;
  height: 20px;
  background: #4d5073;
  font-size: 12px;
  line-height: 16.37px;
  color: #f5f9f8;
  transition: transform 1s;
}
.payNowfont {
  text-align: center;
  padding: 2px;
}
.payNowBtn:hover {
  font-weight: 900;
  color: #f5f9f8;
  transform: scale(1.2);
}
.font_20 {
  text-align: right;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 15px;
}


.paid-txt{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.paid-main-box{
  border-bottom: 2px solid rgba(108, 108, 108, 0.24);
}