.mobileView{
    margin: 0 35% 0 35%;
    border: 1px solid #e2e2e2
}

@media (max-width: 768px) {
    .mobileView{
        margin: 0;
        border: none
    }
}
