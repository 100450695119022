.add-detail-btn{
    border: 1px solid #E63946;
    text-align: center;
    margin: 0 15% 0 15%;
    padding: 5px;
    border-radius: 5px;
    color: #FF2147;
}

.earning-box{
    border: 1px solid #00000033;
    padding: 10px 20px;
}