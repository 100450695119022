.box328 {
  background: #4d507333;
  justify-content: center;
  margin-top: 12px;
  text-align: center;
  width: -webkit-fill-available;
  height: 135px;
  border-radius: 20px;
  /* opacity: 0px; */
  margin: 20px;
}
.fastagBox {
  text-align: left;
  margin-left: 15px;
  font-size: 20px;
  font-weight: 800;
  line-height: 27.28px;
  margin-top: 15px;
}
.fastagBoxDesc {
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  color: #6c6c6c;
  margin-left: 11px;
  margin-top: 5px;
}
.txnDetalis {
  /* height: 62px; */
  width: -webkit-fill-available;
  border: 0.5px solid rgba(108, 108, 108, 0.24);
  /* margin-bottom: -10px; */
}

.txnDetalis-1 {
  /* height: 62px; */
  width: -webkit-fill-available;
  border: 0.5px solid rgba(108, 108, 108, 0.24);
  /* margin-bottom: -10px; */
  justify-content: space-between;
  padding-right: 10px;
}

.font_19 {
  width: 194px;
  height: 25px;
  margin-top: 10px;
  left: 24px;
  color: #25252d;
  font-weight: 400;
  font-size: 18px;
  line-height: 24.55px;
  text-align: left;
  margin-left: 10px;
}
